import React from 'react'
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SidebarForm from "../components/SidebarForm"
import ReactMarkdown from 'react-markdown'
import GallerySlider from '../components/GallerySlider'

import Seo from "../components/seo"
const FenceGalleryTemplate = (props) => {
    console.log('data', props)
    if (!props?.data?.markdownRemark) return null;
    const { title, code, content, featuredimage, gallery } = props?.data?.markdownRemark?.frontmatter
    return (
        <Layout>
            <div className="bg-white drop-shadow-xl border-t-7 md:px-8 px-4 mt-8 pb-6 max-w-6xl mx-auto ">
                <div className="flex md:flex-row flex-col md:text-left text-center">
                    <div className="md:w-8/12 w-full">
                        <h1 className="text-3xl text-gray-600 leading-7 uppercase font-light title pb-5 mt-7 text-center mb-8">{title && title}</h1>
                        <GallerySlider featuredimage={featuredimage} gallery={gallery} />
                        {/* <GatsbyImage image={getImage(featuredimage)} /> */}
                        <p className="text-lg mt-4 font-normal">Product Number : <b>{code}</b></p>

                        {content &&
                            <ReactMarkdown
                                children={content}
                                components={{
                                    p: ({ children }) => {
                                        return (
                                            <p className="font-light text-sm text-gray-600 mb-6 max-w-full">
                                                {children}
                                            </p>
                                        )
                                    },
                                    h1: ({ children }) => {
                                        return (
                                            <h1 className="text-4xl text-gray-600 leading-7 uppercase font-light title pb-5 mt-7 text-center mb-6">
                                                {children}
                                            </h1>
                                        )
                                    },
                                    h2: ({ children }) => {
                                        return (
                                            <h2 className="text-3xl text-gray-600 leading-7 uppercase font-light title pb-5 mt-7 text-center mb-6">
                                                {children}
                                            </h2>
                                        )
                                    },
                                    h3: ({ children }) => {
                                        return (
                                            <h3 className="md:text-3xl text-xl text-gray-600 uppercase mt-5 font-medium title pb-5 mt-7 text-center mb-10 ">
                                                {children}
                                            </h3>
                                        )
                                    },
                                    h4: ({ children }) => {
                                        return (
                                            <h4 className='text-2xl font-bold font3 mb-4'>
                                                {children}
                                            </h4>
                                        )
                                    },
                                    ul: ({ children }) => {
                                        return (
                                            <ul className="md:mb-16 mb-6 pl-2 list-disc">
                                                {children}
                                            </ul>
                                        )
                                    },
                                    li: ({ children }) => {
                                        return (
                                            <li className="text-sm font-normal mb-3">
                                                {children}
                                            </li>
                                        )
                                    },
                                }}
                            />
                        }
                    </div>
                    <div className="md:w-4/12 w-full">
                        <SidebarForm />
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export const Head = (props) => <Seo title={props?.data?.markdownRemark?.frontmatter?.meta?.seoTitle || 'Vinyl Fence Toronto : Residential and Commercial Toronto Wood Fencing'}
    description={props?.data?.markdownRemark?.frontmatter?.meta?.seoDescription || 'Vinyl Fence Toronto : Residential and Commercial Toronto Wood Fencing'} path={props.location.pathname} />


export const pageQuery = graphql`
  query FenceGalleryByID($id: String!) {
    markdownRemark(id: { eq: $id }, frontmatter: {status: {ne: "Draft"}, templateKey: {eq: "fence-gallery"}}) {
        frontmatter {
            title
            code
            content
            gallery {
                childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
                }
            }
            featuredimage {
                childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
                }
            }
            meta {
                seoTitle
            }
            }
    }    
  }
`;
export default FenceGalleryTemplate