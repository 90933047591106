import React from "react"
import Slider from "react-slick";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="absolute -bottom-10 left-28 cursor-pointer z-10" onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" /></svg>    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="absolute -bottom-10 left-16 cursor-pointer z-10" onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" /></svg>
    </div>
  );
};

const GallerySlider = ({ featuredimage, gallery }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  console.log('gallery', gallery)
  return (
    <>
      {(gallery && gallery.length > 0) ? (
        <Slider {...settings} className="gallery-slider-container mb-20">
          {/* Show featured image first */}
          {featuredimage && (
            <div>
              <GatsbyImage image={getImage(featuredimage)} alt="Featured Image" />
            </div>
          )}

          {/* Display gallery images if available */}
          {gallery && gallery.length > 0 && (

            gallery.map((image, index) => (
              <div key={index}>
                <GatsbyImage key={index} image={getImage(image)} alt={`Gallery Image ${index + 1}`} />
              </div>
            ))

          )}
        </Slider>
      ) : (
        <>
          <GatsbyImage image={getImage(featuredimage)} />
        </>
      )}

    </>
  )
}
export default GallerySlider